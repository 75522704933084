<script lang="ts" setup>
import { VueFinalModal } from 'vue-final-modal'
import type { IGetCurrentFieldValueResponse } from '@register'
import { useApiLeaseCurrentValues, useSummaryClipboard } from '@register'
import type { UseQueryReturnType } from '@tanstack/vue-query'
interface Props {
  leaseId: Ref<string>
}

const props = defineProps<Props>()

const emit = defineEmits(['close'])
const companyId = getCurrentDivisionId()
const route = useRoute('division-companyId-portfolio')
const { dateFormat } = useDivisions()
const dayjs = useDayjs()

type Snippet = {
  query: UseQueryReturnType<IGetCurrentFieldValueResponse, Error>
  fieldId: string[]
}
// @ts-expect-error
const leaseIds = computed(() => route.query?.filters?.advanced?.fieldIds ?? [])

const { data, isLoading, isFetching, isError, error } =
  useApiLeaseCurrentValues(companyId, unref(props.leaseId), leaseIds.value)

const items = computed(() => {
  return leaseIds.value.map((id: string) => {
    return {
      fieldId: id,
      // eslint-disable-next-line no-prototype-builtins
      value: data.value?.hasOwnProperty(id) ? data.value[id] : undefined,
    }
  })
})
const { copy } = useSummaryClipboard()
</script>

<template>
  <VueFinalModal
    class="top-0 flex items-start justify-center bg-black/60 transition-all"
    content-class="w-full"
    overlay-transition="vfm-fade"
    :content-transition="{
      enterActiveClass: 'transition duration-300 ease-in-out transform',
      enterFromClass: '-translate-y-6 opacity-0',
      enterToClass: 'translate-y-0 opacity-100',
      leaveActiveClass: 'transition duration-300 ease-in-out transform',
      leaveToClass: 'translate-y-6 opacity-0',
      leaveFromClass: '-translate-y-0 opacity-100',
    }"
  >
    <ModalContainer title="Snippets" close-on-click @close="$emit('close')">
      <div class="grid grid-cols-1 gap-4">
        <section
          v-for="lease of items"
          :key="lease.fieldId"
          class="bg-gray-850 relative flex rounded-md px-4 py-2"
        >
          <div class="relative flex grow flex-col">
            <h2
              class="bg-gray-850 sticky top-12 z-10 w-full text-sm font-medium text-gray-400"
            >
              {{ lease.value?.field?.name ?? title(lease.fieldId) }}
            </h2>

            <div v-if="isLoading">
              <Skeleton height="44px" width="100%" class="mb-2" />
            </div>
            <div v-else-if="isError">
              {{ error }}
            </div>
            <div v-else>
              <RegisterSummaryFieldOptionExerciseDetails
                v-if="
                  lease.value?.field &&
                  lease.value?.field.id === 'OptionExerciseDetails'
                "
                :value="lease.value"
                :field="lease.value?.field"
              />
              <RegisterSummaryFieldRentIncreaseTable
                v-else-if="
                  lease.value?.field && lease.value?.field.id.includes('Table')
                "
                :value="lease.value"
                :field="lease.value?.field"
              />
              <RegisterSummaryFieldPropertyAddress
                v-else-if="
                  lease.value?.field &&
                  lease.value?.field.id === 'PropertyAddress'
                "
                :value="lease.value"
                :field="lease.value?.field"
              />

              <div
                v-else-if="
                  lease.value?.field &&
                  lease.value?.field.id.toLowerCase().includes('date')
                "
                class="flex-auto text-sm print:bg-white"
              >
                <time>
                  {{ dayjs(lease.value.value, 'D/M/YYYY').format(dateFormat) }}
                </time>
              </div>
              <div v-else class="flex-auto text-sm print:bg-white">
                <span
                  class="relative flex flex-col whitespace-pre-line print:bg-white"
                >
                  {{ lease.value.value }}
                </span>
              </div>
            </div>
          </div>

          <div class="">
            <div
              class="bg-gray-850 sticky top-12 z-10 w-full text-sm font-medium text-gray-400"
            >
              <Tooltip
                :content="`Copy to clipboard`"
                class="fixed z-50 text-nowrap border border-gray-700 bg-gray-900 text-xs"
                placement="top"
                outer-class=""
              >
                <Button
                  color="transparentSecondary"
                  class="h-8 w-8 bg-gray-700/5 text-gray-200"
                  icon="copy-clipboard"
                  @click.prevent="copy(lease.value?.field, lease.value?.value)"
                >
                </Button>
              </Tooltip>
            </div>
          </div>
        </section>
      </div>
    </ModalContainer>
  </VueFinalModal>
</template>
