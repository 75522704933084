import { toast } from 'vue3-toastify'
import type { ILeaseSummaryField } from '@register'

export const useSummaryClipboard = () => {
  const clipboard = useClipboard()

  const copy = async (
    field: ILeaseSummaryField,
    value: any | undefined = undefined,
  ) => {
    if (!field.value?.value && !value) {
      return
    }

    try {
      //Property address
      if (field.id === 'PropertyAddress') {
        const text =
          field.value?.value.googleAddress ??
          field.value?.value.address ??
          value?.address ??
          value

        await clipboard.copy(text)
        toast.success('Copied to clipboard')
        return
      }

      //Object and tables
      if (typeof field.value?.value === 'object' || typeof value === 'object') {
        const text = convertToString(field.value?.value ?? value)
        await clipboard.copy(text)
        toast.success('Copied to clipboard')
        return
      }

      // strings
      await clipboard.copy(field.value?.value ?? value)
      toast.success('Copied to clipboard')
      //
    } catch (error) {
      toast.error('Error to copy to clipboard')
    }
  }

  return {
    ...clipboard,
    copy,
  }
}

function objectToString(data?: any): string {
  if (!data) return ''

  let toStr = ''
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      toStr += `${title(key)} ${data[key]}` + ', '
    }
  }
  return toStr
}

function convertToString(data: any) {
  if (Array.isArray(data)) {
    return data
      .map((o) => {
        return JSON.stringify(objectToString(o))
      })
      .join(', ')
  } else {
    return objectToString(data)
  }
}
